/**
 * Navbar
 */

export function navbar() {
  var isHomepage = hasClass($('body'), 'homepage');

  window.toggleMenuHistory = function(backButtonPressed){
    // console.log('toggleMenuHistory', backButtonPressed)
    var menuHash = '#menu'
    var historyStateUrlToSave = window.location.pathname + menuHash; 
    document.body.classList.toggle('navbar--opened');
    if(hasClass(document.body, 'navbar--opened')){
      window.history.pushState( { title: "Zwoliński · Menu" }, "Zwoliński · Menu", historyStateUrlToSave);
    }else if(backButtonPressed !== true || window.location.hash === menuHash){
      window.history.back();
    }
  }
  // Show menu overlay on the mobile devices when click the navbar toggler

  const navbarToggler = document.querySelector('.js-navbar-toggler');

  if (navbarToggler) {

    navbarToggler.addEventListener('click', function (event) {
    	event.preventDefault();
      window.toggleMenuHistory();
    });

  }

  // Add class to navbar after certain amount of pixels are scrolled

  const navbarElement = document.querySelector('.js-navbar');
  const navbarTransparentPixels = 150;

  if (navbarElement) {

    if (navbarElement.classList.contains('navbar--fixed-transparent')) {

      function navbarTransparent() {
        let scrolled = scrollTop();
        let position = navbarElement.offsetTop;
        if (scrolled > position + navbarTransparentPixels) {
          navbarElement.classList.add('navbar--scrolled');
        } else {
          navbarElement.classList.remove('navbar--scrolled');
        }

      }

      window.addEventListener('scroll', debounce(navbarTransparent));

    }

  }

  // Navbar slides out of view when scrolling down and slides back in when scrolling up
  // Based on https://dev.to/changoman/showhide-element-on-scroll-w-vanilla-js-3odm

  if (navbarElement) {

    if (navbarElement.classList.contains('navbar--fixed-showhide')) {

      const navbarShowHidetPixels = 150;
      let scrollPos = 0;

      function navbarShowHide() {

        var scrolled = scrollTop();
        var position = navbarElement.offsetTop;
        if (scrolled > position + navbarShowHidetPixels) {

          let isVisible = navbarElement.classList.contains('is-visible');
          let isHidden = navbarElement.classList.contains('is-hidden');

          let windowY = window.scrollY;
          if (windowY < scrollPos) {
            // Scrolling UP
            if(!isVisible) {
              navbarElement.classList.add('is-visible');
            }
            if(isHidden) {
              navbarElement.classList.remove('is-hidden');
            }
          } else {
            // Scrolling DOWN
            if(!isHidden) {
              navbarElement.classList.add('is-hidden');
            }
            if(isVisible) {
              navbarElement.classList.remove('is-visible');
            }
          }
          scrollPos = windowY;
        }
      }

      window.addEventListener('scroll', debounce(navbarShowHide));

    }

  }

}

export default navbar;

/*
 * @title App
 * @description Application entry point
 */

import navbar from '../modules/navbar/navbar';
import carousel from '../modules/carousel/carousel';
import loader from './components/loader.js';
import lib from './lib.js';

// import './components/progress_buttons.js';

import smoothscroll from 'smoothscroll-polyfill';
import raf from 'raf';
import mobile from 'is-mobile';
// const raf = require('raf')

import anime from 'animejs';
import AOS from 'aos';

const DEBUG = true;

function animatieOnLoad(){
	var elementsToFade = __('fade-on-load');
	for (let i = 0; i < elementsToFade.length; i++) {
		elementsToFade[i].style.opacity = 1;
	}
	if(isHomepage){
		navElement.style.opacity = 1;
	}
	__('hello-image')[0].style.filter = 'brightness(100%)';
	__('hello-image')[0].style.opacity = '1';

	var elementsToDropFromTop = __('drop-from-top-on-load');
	for (let i = 0; i < elementsToDropFromTop.length; i++) {
		elementsToDropFromTop[i].style.bottom = 0;
	}
	
}

var isIe = false;
if (document.documentMode) {
    isIe = true;
}
var isEdgeOrIe = false;
if (isIe || /Edge/.test(navigator.userAgent)) {
    isEdgeOrIe = true;
}

function setSizes(){
	windowWidth = window.innerWidth
	|| document.documentElement.clientWidth
	|| document.body.clientWidth;
	// windowHeight = window.innerHeight
	// || document.documentElement.clientHeight
	// || document.body.clientHeight;

	if(isHomepage){
		helloSectionHeight = helloSection.offsetHeight;
		// curtain.style.top = (helloImage.getBoundingClientRect().top + (helloImage.offsetHeight/2)) + 'px';
		autoScrollHelloSection = $('.section-hello .fixed-container').offsetHeight <= helloSectionHeight ? true : false;
		$('.section-hello .fixed-container').style.position = !isMobile && !isEdgeOrIe && autoScrollHelloSection ? 'fixed' : 'relative';
		
		positionToReach = document.querySelector('#navbar-container-section').offsetTop;
		
		navElement.style.top = positionToReach + 'px';
		// console.log('positionToReach', positionToReach);
		// console.log(helloSectionHeight, $('.section-hello .fixed-container').offsetHeight);
	}

	navBrandElementLeftOffset = navBrandElement.getBoundingClientRect().left;
	// console.log('navBrandElementLeftOffset', navBrandElementLeftOffset);
}

// var windowHeight = 0;
var windowWidth = 0;
var helloSectionHeight = 0;
var isHomepage = false;
var helloImage;
var helloSection;
// var curtain;
var navElement;
var navBrandElement;
var navBrandElementLeftOffset;
var positionToReach;
var scrolled = 0;
var scrolling = false;
var passScroll = false;
var autoScrollHelloSection = false;

var activeMenuItemClass = 'current-menu-item';
var sectionsInMenu = [];
var sectionsIds = [];

var isMobile = false;

var chartStrokeColor = '#888';
var chartInactiveStrokeColor = '#ddd';

function addSectionInMenu(menuItemId,sectionId){
	sectionsInMenu.push({
		menuItemId: menuItemId,
		sectionId: sectionId,
		// top: _(menuItemId).getBoundingClientRect().top,
		// bottom: _(menuItemId).getBoundingClientRect().bottom
	});
	// window.sectionsInMenu = sectionsInMenu;
	return;
}

function getSectionPosY(menuItemId){
	return _(menuItemId).getBoundingClientRect().top;
}

function getActiveSection(){
	var activeItems = $$('.menu-item.current-menu-item');
	if(activeItems.length > 0 && activeItems[0].id != null) return activeItems[0].id;
}

window.getActiveSection = getActiveSection;

function absolutePosition(el) {
    var
        found,
        left = 0,
        top = 0,
        width = 0,
        height = 0,
        offsetBase = absolutePosition.offsetBase;
    if (!offsetBase && document.body) {
        offsetBase = absolutePosition.offsetBase = document.createElement('div');
        offsetBase.style.cssText = 'position:absolute;left:0;top:0';
        document.body.appendChild(offsetBase);
    }
    if (el && el.ownerDocument === document && 'getBoundingClientRect' in el && offsetBase) {
        var boundingRect = el.getBoundingClientRect();
        var baseRect = offsetBase.getBoundingClientRect();
        found = true;
        left = boundingRect.left - baseRect.left;
        top = boundingRect.top - baseRect.top;
        width = boundingRect.right - boundingRect.left;
        height = boundingRect.bottom - boundingRect.top;
    }
    return {
        found: found,
        left: left,
        top: top,
        width: width,
        height: height,
        right: left + width,
        bottom: top + height
    };
}

var lastScrollTop;
function setActiveSectionInMenu(scrollTop){
	if(lastScrollTop === scrollTop) {
		return;
	}
	var isAnySectionActive = false;
	for (var i = sectionsInMenu.length - 1; i >= 0; i--) {
		let sectionBoundingRect = absolutePosition(_(sectionsInMenu[i].sectionId));
		if(sectionBoundingRect.top <= scrollTop && sectionBoundingRect.bottom > scrollTop){
			addClass(_(sectionsInMenu[i].menuItemId), activeMenuItemClass);
			isAnySectionActive = true;
			titleUpdater(sectionsInMenu[i].sectionId);
		}else{
			removeClass(_(sectionsInMenu[i].menuItemId), activeMenuItemClass);
		}
	}
	if(!isAnySectionActive) titleUpdater();
	lastScrollTop = scrollTop;
}

function scrollToSection(event){
	if (scrolled < positionToReach) passScroll = true;
	let sectionId = event.target.id.slice(0,-10);
	if(sectionId == ''){
		let href = event.target.href;
		sectionId = href.substr(href.indexOf("#") + 1);
	}
	// sectionId = sectionId + '-menu-item';
	window.scrollTo(0, absolutePosition(_(sectionId)).top);
	setTimeout(function () {
		removeClass(document.body,'navbar--opened');
	}, 100);
}

window.scrollToSection = scrollToSection;

function initSectionsIndicatorsInMenu(){
	sectionsInMenu = [];
	for (var i = sectionsIds.length - 1; i >= 0; i--) {
		addSectionInMenu(sectionsIds[i]+'-menu-item', sectionsIds[i]);
		_(sectionsIds[i]+'-menu-item').addEventListener("click", scrollToSection);
	}


	// console.log(sectionsInMenu);
}

var lastCursorState;
var lastCursorX;
var lastCursorY;

function setCurosrStyle(){
	if(lastCursorState === cursorState && lastCursorX === cursorX && lastCursorY === cursorY) {
		return;
	}
	cursor.style.opacity = cursorState === 1 ? '1' : '0';
	cursor.style.transform = 'translate(' + cursorX +'px,' + cursorY + 'px)';
	lastCursorState = cursorState;
	lastCursorState = cursorX;
	lastCursorState = cursorY;
}

var morphingSkillsMap; 

function mainLoopAnimation() {
	let scrollPos = scrollTop();
	// let reached = false;
	var reached = navElement.classList.contains('reached');

	if (scrollPos >= positionToReach) {
		if(!reached) {
			navElement.classList.add('reached');
		}
		// helloSection.style.opacity = 0;
	}else{
		if(reached) {
			navElement.classList.remove('reached');
		}
		// helloSection.style.opacity = 1;
	}

	// passScroll = false;
	// scrolled = scrollPos;

	// absolutePosition(morphingSkillsMap);

	//anime.js causes odd white flashes on mobile chrome
	//so set display: none when not in screen
	let skillsMapDisplay = morphingSkillsMap.style.display;
	if(scrollPos > absolutePosition(morphingSkillsMap).bottom + 10){
		if(skillsMapDisplay !== 'none') {
			morphingSkillsMap.style.display = 'none';
		}
	}else{
		if(skillsMapDisplay !== 'block') {
			morphingSkillsMap.style.display = 'block';
		}
	}

	if(!isMobile){
		updateTransformStyle();
		setCurosrStyle();
	}

	// curtain.style.height = percentage + 'vh';
	// curtain.style.width = percentage + 'vh';
	setActiveSectionInMenu(scrollPos);
	raf(mainLoopAnimation);
}

var skillsChartState = 0;
var skillsLabels = null;

var labelsStyles = [
	{'top':'-2%','left':'2%','transform':'translateX(0)','opacity':'1'},
	{'top':'3%','left':'23%','transform':'translateX(0)','opacity':'1'},
	{'top':'12%','left':'38%','transform':'translateX(0)','opacity':'1'},
	{'top':'25%','left':'50%','transform':'translateX(0)','opacity':'1'},
	{'top':'40%','left':'57%','transform':'translateX(0)','opacity':'1'},
	{'top':'60%','left':'57%','transform':'translateX(0)','opacity':'1'},
	{'top':'75%','left':'50%','transform':'translateX(0)','opacity':'1'},
	{'top':'88%','left':'38%','transform':'translateX(0)','opacity':'1'},
	{'top':'97%','left':'23%','transform':'translateX(0)','opacity':'1'},
	{'top':'102%','left':'2%','transform':'translateX(0)','opacity':'0.25'},
	{'top':'102%','left':'-15%','transform':'translateX(-100%)','opacity':'0.25'},
	{'top':'97%','left':'-36%','transform':'translateX(-100%)','opacity':'0.25'},
	{'top':'88%','left':'-52%','transform':'translateX(-100%)','opacity':'0.25'},
	{'top':'75%','left':'-63%','transform':'translateX(-100%)','opacity':'0.25'},
	{'top':'60%','left':'-70%','transform':'translateX(-100%)','opacity':'0.25'},
	{'top':'40%','left':'-70%','transform':'translateX(-100%)','opacity':'0.25'},
	{'top':'25%','left':'-63%','transform':'translateX(-100%)','opacity':'0.25'},
	{'top':'12%','left':'-52%','transform':'translateX(-100%)','opacity':'0.25'},
	{'top':'-82%','left':'-15%','transform':'translateX(-100%)','opacity':'0'},
	{'top':'-72%','left':'-15%','transform':'translateX(-100%)','opacity':'0'},
	{'top':'-62%','left':'-15%','transform':'translateX(-100%)','opacity':'0'},
	{'top':'-52%','left':'-15%','transform':'translateX(-100%)','opacity':'0'},
	{'top':'-42%','left':'-15%','transform':'translateX(-100%)','opacity':'0'},
	{'top':'-32%','left':'-15%','transform':'translateX(-100%)','opacity':'0'},
	{'top':'-22%','left':'-15%','transform':'translateX(-100%)','opacity':'0'},
	{'top':'-2%','left':'-15%','transform':'translateX(-100%)','opacity':'0.25'},
	{'top':'3%','left':'-36%','transform':'translateX(-100%)','opacity':'0.25'}
];

function turnSkillsChart(dir){
	if(dir !== 1 && dir !== -1) return;
	if(skillsLabels == null) return;

	skillsChartState = skillsChartState + dir;

	if(skillsChartState === -1) skillsChartState = 2;
	if(skillsChartState === 3) skillsChartState = 0;

	turnSkillsChartByIndex(skillsChartState);
}

function turnSkillsChartByIndex(index){
	if(!isHomepage || isIe) return;
	if(skillsLabels == null) skillsLabels = $$('.skill-label');
	skillsChartState = index;
	if(skillsChartState > 2 || skillsChartState < 0) return;
	for (var i = skillsLabels.length - 1; i >= 0; i--) {
		var labelIndex = (i + (skillsChartState * 9)) % skillsLabels.length;
		skillsLabels[i].style.top = labelsStyles[labelIndex].top;
		skillsLabels[i].style.left = labelsStyles[labelIndex].left;
		skillsLabels[i].style.transform = labelsStyles[labelIndex].transform;
		skillsLabels[i].style.opacity = labelsStyles[labelIndex].opacity;
	}
	if(skillsChartState === 0){
		$('.frontend-skills').style['stroke'] = chartStrokeColor;
		$('.frontend-skills').setAttribute('opacity', 1);
		$('.backend-skills').style['stroke'] = chartInactiveStrokeColor;
		$('.backend-skills').setAttribute('opacity', 0.1);
		$('.other-skills').style['stroke'] = chartInactiveStrokeColor;
		$('.other-skills').setAttribute('opacity', 0.1);
	}else if(skillsChartState === 1){
		$('.frontend-skills').style['stroke'] = chartInactiveStrokeColor;
		$('.frontend-skills').setAttribute('opacity', 0.1);
		$('.backend-skills').style['stroke'] = chartStrokeColor;
		$('.backend-skills').setAttribute('opacity', 1);
		$('.other-skills').style['stroke'] = chartInactiveStrokeColor;
		$('.other-skills').setAttribute('opacity', 0.1);
	}else{
		$('.frontend-skills').style['stroke'] = chartInactiveStrokeColor;
		$('.frontend-skills').setAttribute('opacity', 0.1);
		$('.backend-skills').style['stroke'] = chartInactiveStrokeColor;
		$('.backend-skills').setAttribute('opacity', 0.1);
		$('.other-skills').style['stroke'] = chartStrokeColor;
		$('.other-skills').setAttribute('opacity', 1);
	}
}

window.turnSkillsChart = turnSkillsChart;
window.turnSkillsChartByIndex = turnSkillsChartByIndex;

var points = [
	94.4459753267812,57.03955092681039,90.09529358847655,70.4295724882796,81.81980515339464,81.81980515339464,70.4295724882796,90.09529358847655,57.03955092681039,94.4459753267812,42.960449073189615,94.4459753267812,29.570427511720396,90.09529358847655,18.180194846605364,81.81980515339464,9.904706411523449,70.4295724882796,5.554024673218805,57.03955092681039,5.554024673218798,42.960449073189615,9.904706411523456,29.570427511720382,18.180194846605353,18.180194846605364,29.57042751172039,9.904706411523449,42.9604490731896,5.554024673218805,57.03955092681038,5.554024673218798,70.4295724882796,9.904706411523442,81.81980515339463,18.180194846605353,90.09529358847655,29.570427511720386,94.4459753267812,42.9604490731896
]

var getRandomInt = function(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min)) + min;
}

function buildPolygonStr(pts, rnd){
	var strToReturn = '';
	for (var i = 0; i < pts.length; i = i + 2) {
		if(rnd){
			strToReturn = strToReturn + (pts[i] + (getRandomInt(-200, 200)/100)) + ',' + (pts[i+1] + (getRandomInt(-200, 200)/100)) + ' ';
		}else{
			strToReturn = strToReturn + pts[i] + ',' + pts[i+1] + ' ';
		}
	}
	return strToReturn;
}

// window.points = points;
// window.buildPolygonStr = buildPolygonStr;

// ===========================================================
// See tutorial at : 
// https://css-tricks.com/animate-a-container-on-mouse-over-using-perspective-and-transform/
// ===========================================================
var perspectiveX;
var perspectiveY;

var lastPerspectiveX;
var lastPerspectiveY;

var updateTransformStyle = function() {
	if(lastPerspectiveX !== perspectiveX || lastPerspectiveY !== perspectiveY) {
		var style = "rotateX(" + (perspectiveX) + "deg) rotateY(" + (perspectiveY) + "deg)";
		innerPerspective.style.transform = style;
		innerPerspective.style.webkitTransform = style;
		innerPerspective.style.mozTranform = style;
		innerPerspective.style.msTransform = style;
		innerPerspective.style.oTransform = style;
	
		var style2 = "rotateX(" + (perspectiveX*20) + "deg) rotateY(" + (perspectiveY*20) + "deg)";
		innerPerspective2.style.transform = style2;
		innerPerspective2.style.webkitTransform = style2;
		innerPerspective2.style.mozTranform = style2;
		innerPerspective2.style.msTransform = style2;
		innerPerspective2.style.oTransform = style2;
	
		lastPerspectiveX = perspectiveX;
		lastPerspectiveY = perspectiveY;
	}
};

var cursorX = 0;
var cursorY = 0;
var cursorState = 0;
var cursor;

var initCursor = function(){
	cursor = _('cursor');

	function setCursorState(state){
		cursorState = state;
	}

	let interactiveCursorElements = $$('img, .device');
	// console.log('interactiveCursorElements', interactiveCursorElements);
	for (var i = 0; i < interactiveCursorElements.length; i++) {
		interactiveCursorElements[i].addEventListener("mouseover", function(){
			setCursorState(1);
		});
		interactiveCursorElements[i].addEventListener("mouseout", function(){
			setCursorState(0);
		});
	}
	function setPointerPosition(e) {
		cursorX = e.clientX;
		cursorY = e.clientY;
	}
	window.addEventListener('mousemove', function(e) { setPointerPosition(e); });
}

var initPerspective = function() {
	// Mouse
	var mouse = {
		_x: 0,
		_y: 0,
		x: 0,
		y: 0,
		updatePosition: function(event) {
			var e = event || window.event;
			this.x = e.clientX - this._x;
			this.y = (e.clientY - this._y) * -1;
		},
		setOrigin: function(e) {
			this._x = e.offsetLeft;// - Math.floor(e.offsetWidth	/ 2);
			this._y = e.offsetTop + Math.floor(e.offsetHeight / 2);
		},
		show: function() {
			return "(" + this.x + ", " + this.y + ")";
		}
	};

	// Track the mouse position relative to the center of the container.
	mouse.setOrigin(containerPerspective);

	//----------------------------------------------------

	var counter = 0;
	var refreshRate = 10;
	var isTimeToUpdate = function() {
		return counter++ % refreshRate === 0;
	};

	//----------------------------------------------------

	var onMouseEnterHandler = function(event) {
		update(event);
	};

	var onMouseLeaveHandler = function() {
		innerPerspective.style.transform = '';
		innerPerspective.style.webkitTransform = '';
		innerPerspective.style.mozTranform = '';
		innerPerspective.style.msTransform = '';
		innerPerspective.style.oTransform = '';
		
		innerPerspective2.style.transform = '';
		innerPerspective2.style.webkitTransform = '';
		innerPerspective2.style.mozTranform = '';
		innerPerspective2.style.msTransform = '';
		innerPerspective2.style.oTransform = '';
	};

	var onMouseMoveHandler = function(event) {
		if (isTimeToUpdate()) {
			update(event);
		}
	};

	//----------------------------------------------------

	var update = function(event) {
		mouse.updatePosition(event);
		perspectiveX=(mouse.y / innerPerspective.offsetHeight / 2).toFixed(2)
		perspectiveY=(mouse.x / innerPerspective.offsetWidth / 2).toFixed(2)
	};

	//--------------------------------------------------------

	containerPerspective.onmousemove = onMouseMoveHandler;
	containerPerspective.onmouseleave = onMouseLeaveHandler;
	containerPerspective.onmouseenter = onMouseEnterHandler;
};


var containerPerspective;
var innerPerspective;
var innerPerspective2;


function createArray(length) {
	var arr = new Array(length || 0),
		i = length;

	if (arguments.length > 1) {
		var args = Array.prototype.slice.call(arguments, 1);
		while(i--) arr[length-1 - i] = createArray.apply(this, args);
	}

	return arr;
}

function initConsoleGame(){
	var headStyle = 'font-weight:bold;font-size:30px;color:red';
	var rocketStyle = 'color:red;font-weight:bold';
	var blackColor = 'color:#000';
	var whiteColor = 'color:#fff';
	var grayColor = 'color:#888';

	var score = 0;
	var lines;

	var gameState = 0;

	var gameHeight = 5;
	var gameWidth = 80;

	lines = createArray(gameHeight, gameWidth);

	console.log('type \'new game\' to play game! then back click on page.');
	//0-before 'new game'
	//1-before click
	//2-after click, started
	var rocketPosition = 2;

	window.game = function() {
		score = 0;
		gameState = 1;
		probabilityValue = 0.01;
		console.clear();
		console.log('%cSet ready and click on page!\n\n', headStyle);
		console.log('UP/DOWN - MOVE');
		for (let i = 0; i < lines.length; i++) {
			lines[i] = new Array(gameWidth).fill(0);
		}
	}

	var frameCounter = 0;

	function getStringFromLine(line){
		var strToReturn = (line % 2 ? '%c`' : '%c\'') + '___________%c';
		if(rocketPosition === line){
			strToReturn = (frameCounter % 2 ? '%c``\'`' : '%c`\'`\'') + '~~%c|>>==>%c'
		}
		for (let j = 0; j < lines[line].length; j++) {
			strToReturn = strToReturn + (lines[line][j] !== 0 ? 'X' : ' ');
		}

		if(rocketPosition === line){
			console.log(strToReturn, grayColor, rocketStyle, blackColor );
		}else{
			console.log(strToReturn, whiteColor,blackColor);
		}	
		
	}

	document.addEventListener('keydown', moveRocket);

	function probability() {
		return Math.random() <= probabilityValue;
	}

	var probabilityValue = 0.025;

	function frame(){
		if(gameState !== 2) return;
		frameCounter = frameCounter + 1;
		console.clear();
		for (let i = 0; i < lines.length; i++) {
			getStringFromLine(i);
			lines[i].shift();
			score = score + 1;
			if(i == rocketPosition && lines[i][0] == 1){
				gameState = 0;
			}
			lines[i].push(0);
		}

		if(probability() == true){
			let rnd = Math.floor(Math.random() * gameHeight);
			lines[rnd][gameWidth - 1] = 1;
		}

		if(gameState === 0){
			console.error('%cGAME OVER! type \'new game\' to restart.',headStyle);
		}

		if(frameCounter > 100) {
			score = score + 1000;
			frameCounter = 0;
			probabilityValue = probabilityValue + 0.05;
		}
		console.warn('score = ', score);
		setTimeout(frame, 100);
	}

	function startRocket(){
		if(gameState !== 1) return;
		gameState = 2;
		frame();
	}

	document.addEventListener('click', startRocket);

	function moveRocket(e) {
		if(gameState !== 2) return;
		if(e.code === 'ArrowUp'){
			rocketPosition = rocketPosition - 1;
			if (rocketPosition < 0) rocketPosition = 0;
		}else if(e.code === 'ArrowDown'){
			rocketPosition = rocketPosition + 1;
			if (rocketPosition >= gameHeight) rocketPosition = gameHeight - 1;
		}
	}
}

function titleUpdater(sectionId){
	if(isMobile || document.hidden || !document.hasFocus()) return;
	var baseTitle = 'Zwoliński · ';

	if(sectionId == null){
		return document.title = baseTitle + 'Home';
	}

	if(sectionId === 'projects-section' && window.lang === 'en'){
		return document.title = baseTitle + 'Projects';
	}

	if(sectionId === 'projects-section' && window.lang === 'pl'){
		return document.title = baseTitle + 'Projekty';
	}

	if(sectionId === 'skills-section' && window.lang === 'en'){
		return document.title = baseTitle + 'Skills & Services';
	}

	if(sectionId === 'skills-section' && window.lang === 'pl'){
		return document.title = baseTitle + 'Umiejętności';
	}
}

var isTabActive = true;
var titleBeforeInactive = 'Zwoliński · Home';
var titleAnimation;
var textToAnimation = window.lang === 'pl' ? 'KLIKNIJ TUTAJ BY POWRÓCIĆ · ZWOLIŃSKI · ' : 'CLICK HERE TO BACK · ZWOLIŃSKI · '
textToAnimation = textToAnimation + textToAnimation;
var animationPosition = 0;
var maxTitleAnimationPosition = textToAnimation.length;

function onWindowFocus() { 
  isTabActive = true; 
  document.title = titleBeforeInactive;
  clearInterval(titleAnimation);
  titleAnimation = null;
}

function onWindowBlur() { 
	isTabActive = false; 
	titleBeforeInactive = document.title;
	animationPosition = 0;
	titleAnimation = setInterval(function () { 
		if((!document.hidden && !document.hasFocus()) || isMobile) return document.title = titleBeforeInactive;
		var titleFrameAnimation = animationPosition % maxTitleAnimationPosition;
		var newTitle = textToAnimation.substring(titleFrameAnimation) + textToAnimation.substring(0, titleFrameAnimation);
		document.title = newTitle;
		animationPosition = animationPosition + 1;
	}, 100);
}

var prevType;
function toggleWindowFocusHandler(e){
    if (prevType != e.type) {   //  reduce double fire issues
        switch (e.type) {
            case "blur":
                onWindowBlur()
                break;
            case "focus":
                onWindowFocus();
                break;
        }
    }
    prevType =  e.type;
}

window.addEventListener('focus', function(e){toggleWindowFocusHandler(e)});
window.addEventListener('blur', function(e){toggleWindowFocusHandler(e)});

var lazyImages = [];
function lazyLoad() {
	loadDevicesCssBackgroundImages();
	if(lazyImages.length === 0) return
	for (var i = lazyImages.length - 1; i >= 0; i--) {
        if (lazyImages[i].offsetTop < window.innerHeight + window.pageYOffset + 300) {
            lazyImages[i].src = lazyImages[i].dataset.src
            lazyImages[i].addEventListener('load', function(event){
				removeClass(event.target, 'lazy-image');
            })
        }
	}
	lazyImages = $$('.lazy-image');
}

var devices = [];
function loadDevicesCssBackgroundImages() {
	if(devices.length === 0) return
	for (var i = devices.length - 1; i >= 0; i--) {
        if (devices[i].offsetTop < window.innerHeight + window.pageYOffset + 300) {
			removeClass(devices[i], 'device-to-load');
			addClass(devices[i], 'device');
        }
	}
	devices = $$('.device-to-load');
}

let scrollToWelcome;
const main = document.getElementsByTagName("main")[0];
document.addEventListener('DOMContentLoaded', function() {
	lib();

	isMobile = mobile();

	// kick off the polyfill!
	smoothscroll.polyfill();

	navBrandElement = $('nav .navbar__brand');
	lazyImages = $$('.lazy-image');
	devices = $$('.device-to-load');

	isHomepage = hasClass($('body'), 'homepage');

	navbar();
	carousel();
	loader();

	window.addEventListener('scroll', throttle(lazyLoad, 16))
	window.addEventListener('resize', throttle(lazyLoad, 16))
	lazyLoad();

	if(isHomepage){
		sectionsIds = ['projects-section', 'skills-section'];
		helloImage = $('.hello-image');
		helloSection = $('section.section-hello');

		morphingSkillsMap = $('.morphing-skills-map svg g');

		if(isIe){
			helloImage.style.transform = 'none';
		}

		// console.log(sectionsInMenu);
		if(!isMobile){
			containerPerspective = document.getElementById("perspective-container");
			innerPerspective = document.getElementById("perspective-inner");
			innerPerspective2 = document.getElementById("perspective-inner-2");
			initPerspective();
		}
		// curtain = $('.curtain');
		navElement = $('nav');

		anime({
			targets: ['.morphing-skills-map .filter-effect'],
			points: buildPolygonStr(points, false),
			baseFrequency: 0,
			duration: 16000,
			loop: true,
			direction: 'alternate',
			easing: 'easeInOutExpo'
		});
		
		anime({
			targets: '.morphing-skills-map .frontend-skills',
			points: [
				{ value: buildPolygonStr(points, true) },
				{ value: buildPolygonStr(points, true) },
				{ value: buildPolygonStr(points, true) },
				{ value: buildPolygonStr(points, true) },
				{ value: buildPolygonStr(points, false) },
			],
			easing: 'easeOutQuad',
				direction: 'reverse',
			duration: 20000,
			loop: true
		});

		anime({
			targets: '.morphing-skills-map .backend-skills',
			points: [
				{ value: buildPolygonStr(points, true) },
				{ value: buildPolygonStr(points, true) },
				{ value: buildPolygonStr(points, true) },
				{ value: buildPolygonStr(points, true) },
				{ value: buildPolygonStr(points, false) },
			],
			easing: 'easeOutQuad',
			duration: 20000,
			loop: true
		});

		anime({
			targets: '.morphing-skills-map .other-skills',
			points: [
				{ value: buildPolygonStr(points, true) },
				{ value: buildPolygonStr(points, true) },
				{ value: buildPolygonStr(points, true) },
				{ value: buildPolygonStr(points, true) },
				{ value: buildPolygonStr(points, false) },
			],
			easing: 'easeOutQuad',
			direction: 'reverse',
			duration: 30000,
			loop: true
		});

		animatieOnLoad();
		raf(mainLoopAnimation);
	}

	setSizes();
	window.onresize = function() {
		setSizes();
		initSectionsIndicatorsInMenu();
	};

	initSectionsIndicatorsInMenu();

	AOS.init();
	// AOS.init({disable: isMobile});
	if(!isMobile){
		initConsoleGame();
		initCursor();
		// AOS.init();
		// AOS.init({disable: 'mobile'});
	}else{
		// let aosElements = $$('.aos-element');
		// for (var k = aosElements.length - 1; k >= 0; k--) {
		// 	aosElements[k].dataset.aos = '';
		// 	addClass(aosElements[k], 'aos-disabled');
		// }
	}

	let videos = $$('video');
	for (var k = videos.length - 1; k >= 0; k--) {
		videos[k].autoplay = true;
		videos[k].load();
	}

	// scrollToWelcome = new AutoScroll(
	// 	main,
	// 	document.getElementById('skills-section'),
	// 	0,
	// 	function () {
	// 		console.log("scrolled to welcome");
	// 		main.classList.add("intro-scrolled");
	// 	},
	// 	200,
	// 	function () {
	// 		main.classList.remove("intro-scrolled");
	// 	},
	// 	function () {
	// 		main.classList.add("intro-scrolled");
	// 	}
	// );

	console.log('%cg-zwolinski.pl\n', 'font-weight: bold; font-size: 50px;color: #111; text-shadow: 3px 3px 0 #444 , 6px 6px 0 #888 ');
})

class AutoScroll {
  
	constructor(container, element, triggerPoint, callback, duration, prepare, start) {
		// console.log(container);
		const self = this;
		this.container = container;
		this.element = element;
		this.element.scrollingTo = false;
		this.callback = callback;
		this.duration = duration;
		this.triggerPoint = triggerPoint;
		this.autoscrolling = false;
		this.debug = DEBUG || false;
		this.interval = 10;
		this.scrollingTo;
		this.to;
		
		
		container.onscroll = function ($event) {
			self.debug &&
				console.log("onscroll", self.triggerPoint, self.container.scrollTop, self.autoscrolling, self.element.scrollingTo);
		
			if (self.container.scrollTop <= self.triggerPoint) {
				self.element.scrollingTo = false;
				prepare && prepare();
				return;
			}
		
			if (self.autoscrolling) {
				$event.preventDefault();
				return;
			}
		
			if (!self.element.scrollingTo && self.container.scrollTop > self.triggerPoint) {
				self.scrollToElement();
				self.container.classList.add('scrolling');
				start && start();
			}
		};
	}
  
	scrollToElement() {
		if (this.element.scrollingTo) {
			return;
		}
		this.element.scrollingTo = true;
		this.scrollTo(this.duration, function () {
			this.element.scrollingTo = false;
			DEBUG && console.log("element.scrollingTo", this.element.scrollingTo);
		});
	}
  
	endAutoscroll() {
		this.autoscrolling = false;
		this.container.classList.remove('scrolling');
		this.callback && this.callback();
	}
  
	scrollTo(duration) {
		const self = this;
		this.autoscrolling = true;
  
		if (duration <= 0) {
			this.endAutoscroll();
			return;
		}
  
		var difference = this.element.offsetTop - this.container.scrollTop;
		// var perTick = (difference / duration) * this.interval;
		var perTick = (difference * 3 / duration) * this.interval;
  
		setTimeout(function () {
			self.container.scrollTop = self.container.scrollTop + perTick;
			if (self.container.scrollTop === self.element.offsetTop) {
				self.endAutoscroll();
				return;
			}
			self.scrollTo(duration - self.interval);
		}, this.interval);
	}
  }
export function lib() {
  function _(id){return document.getElementById(id);}
  function __(className){return document.getElementsByClassName(className);}
  function $(selector){return  document.querySelector(selector);}
  function $$(selector){return  document.querySelectorAll(selector);}

  // Debounce function which waits 10ms before firing. We use it so we don't fire events like crazy during long scroll sessions

  function debounce(func, wait = 10, immediate = true) {
    let timeout;
    return function() {
      let context = this, args = arguments;
      let later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      let callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  // Returns a function, that, when invoked, will only be triggered at most once
  // during a given window of time. Normally, the throttled function will run
  // as much as it can, without ever going more than once per `wait` duration;
  // but if you'd like to disable the execution on the leading edge, pass
  // `{leading: false}`. To disable execution on the trailing edge, ditto.
  function throttle(func, wait, options) {
    var context, args, result;
    var timeout = null;
    var previous = 0;
    if (!options) options = {};
    var later = function() {
      previous = options.leading === false ? 0 : Date.now();
      timeout = null;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    };
    return function() {
      var now = Date.now();
      if (!previous && options.leading === false) previous = now;
      var remaining = wait - (now - previous);
      context = this;
      args = arguments;
      if (remaining <= 0 || remaining > wait) {
        if (timeout) {
          clearTimeout(timeout);
          timeout = null;
        }
        previous = now;
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      } else if (!timeout && options.trailing !== false) {
        timeout = setTimeout(later, remaining);
      }
      return result;
    };
  };

  function createHtmlNode(elementType, htmlCode, className, id, style) {
    // create html node
    var htmlNode = document.createElement(elementType);
    htmlNode.innerHTML = htmlCode
    htmlNode.className = className;
    htmlNode.id = id;
    htmlNode.style = style;
    return htmlNode;
  }

  function scrollTop() {
    //document.scrollingElement.scrollTop
    return typeof window.pageYOffset != 'undefined' ? window.pageYOffset: document.documentElement.scrollTop? document.documentElement.scrollTop: document.body.scrollTop? document.body.scrollTop:0;
  }

  function clog(...args){
    window.debugMode && console.log(...args)
  }

  function dlog(text){
    window.debugMode && (_('console').innerHTML = text);
  }

  function hasClass(element, className) {
      return (' ' + element.className + ' ').indexOf(' ' + className+ ' ') > -1;
  }

  function addClass(element, className){
    if(hasClass(element, className)) return;
    element.classList.add(className);
  }

  function removeClass(element, className){
    if(!hasClass(element, className)) return;
    element.classList.remove(className);
  }

  function toggleClass(element, className){
    if(hasClass(element, className)) return element.classList.remove(className);
    element.classList.add(className);
  }

  function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  function removeWhiteSpaces(text){
    return text.replace(/\s/g,'');
  }

  window._ = _;
  window.__ = __;
  window.$ = $;
  window.$$ = $$;
  window.createHtmlNode = createHtmlNode;
  window.clog = clog;
  window.dlog = dlog;
  window.hasClass = hasClass;
  window.addClass = addClass;
  window.removeClass = removeClass;
  window.toggleClass = toggleClass;
  window.pad = pad;
  window.removeWhiteSpaces = removeWhiteSpaces;
  window.debounce = debounce;
  window.throttle = throttle;
  window.scrollTop = scrollTop;
}

export default lib;
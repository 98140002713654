export function loader() {

  const loader = document.querySelector('.loader');
  const main = document.getElementsByTagName("main")[0];

  if (loader) {
    setTimeout(function(){
    	loader.classList.add('hidden');
    	main.classList.add('loaded');
    }, 150)
    setTimeout(function(){
      loader.style.display = 'none';
    }, 1650)
  }

}

export default loader;